<template>
  <v-container class="py-0">
    <v-card outlined class="mx-auto mb-4" max-width="1520">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-row no-gutters class="px-5 py-3 align-center">
        <h2 style="font-size: 1.5rem" class="font-weight-regular">
          Clientes banidos
        </h2>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          outlined
          large
          :block="$vuetify.breakpoint.smAndDown"
          color="accent"
          dark
          :class="`px-4 text-none elevation-0 text-body-1 font-weight-bold ${
            $vuetify.breakpoint.smAndDown ? 'mb-2' : ''
          } mr-2`"
          @click="pushToClients()"
        >
          <v-icon left>mdi-account-supervisor</v-icon>
          Gerenciar clientes
        </v-btn>
        <v-btn
          :loading="loading"
          large
          :block="$vuetify.breakpoint.smAndDown"
          color="accent"
          dark
          :class="`px-4 text-none elevation-0 text-body-1 font-weight-bold ${
            $vuetify.breakpoint.smAndDown ? 'mb-2' : ''
          }`"
          @click="showDialogBanClient = true"
        >
          <v-icon left>mdi-account-cancel-outline</v-icon>
          Banir cliente
        </v-btn>
      </v-row>
      <v-dialog
        v-model="showDialogBanClient"
        transition="dialog-bottom-transition"
        width="500"
        overlay-opacity="0.2"
        class="mb-4"
      >
        <v-card
          v-if="showDialogBanClient"
          color="white"
          class="elevation-0 grey--text text--darken-1"
          height="210"
          tile
        >
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="resetBanClientDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Banir cliente</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-autocomplete
            :loading="loading"
            v-model="companySelection"
            :items="unbannedCompanies"
            item-text="name"
            item-value="key"
            :filter="customFilter"
            filled
            dense
            clearable
            hide-no-data
            hide-selected
            label="Empresas"
            placeholder="Pesquisar por nome ou domínio"
            class="mx-4 mt-4"
          >
            <template v-slot:item="{ item }">
              {{ item.name }} ({{ item.main_domain }})
            </template>
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
          </v-autocomplete>
          <div class="float-right mr-4 mb-4">
            <v-btn color="grey" text @click="resetBanClientDialog()">
              Cancelar
            </v-btn>
            <v-btn
              color="accent"
              :loading="loading"
              :disabled="!companySelection || isUnbanningCompany"
              @click="showConfirmBanClient = true"
            >
              Banir cliente
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-divider />
      <v-data-table
        :headers="headers"
        :items="bannedCompanies"
        :items-per-page="itemsPerPage"
        :footer-props="{ itemsPerPageOptions: itemsOptions }"
        :height="tableHeight"
        :loading="loading"
      >
        <template v-slot:[`item.name`]="{ item }">
          <span
            class="blue--text text--darken-2 text-body-2 font-weight-medium link"
            @click.stop="
              $router.push({
                path: '/partner/customer',
                query: { domain: item.main_domain, key: item.key },
              })
            "
            >{{ item.name }}
          </span>
        </template>
        <template v-slot:[`item.ban_info.ban_date`]="{ item }">
          {{
            Object.keys(item.ban_info).length
              ? formatDateAndHour(item.ban_info.ban_date, true)
              : "-"
          }}
        </template>
        <template v-slot:[`item.ban_info.author_name`]="{ item }">
          <div v-if="Object.keys(item.ban_info).length">
            <v-avatar left size="30" class="mr-4">
              <img :src="item.ban_info.author_photo" />
            </v-avatar>
            <span
              class="blue--text text--darken-2 text-body-2 font-weight-medium link"
              @click.stop="
                $router.push({
                  path: '/user',
                  query: { id: item.ban_info.author_id_google },
                })
              "
              >{{ item.ban_info.author_name }}
            </span>
          </div>
          <span v-else>-</span>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            :disabled="loading"
            text
            color="accent"
            @click="
              (companySelection = item.key),
                (showConfirmBanClient = true),
                (isUnbanningCompany = true)
            "
          >
            <span>Desbanir</span>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-card outlined class="mx-auto mb-10 mt-5" max-width="1520">
      <v-expansion-panels flat v-model="showReports">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h2 style="font-size: 1.5rem" class="font-weight-regular">
              Relatórios
            </h2>
            <div class="d-flex justify-end" v-if="showPanel">
              <v-btn
                :disabled="reportsLoading"
                icon
                color="accent"
                class="mr-3"
                @click.stop="getBanLogs"
              >
                <v-icon id="reloadButton">mdi-reload</v-icon>
              </v-btn>
            </div>
          </v-expansion-panel-header>
          <v-divider v-if="panel === 0" />
          <v-expansion-panel-content>
            <v-data-table
              :items="banLogs"
              :headers="banHeaders"
              fixed-header
              item-key="log_changed_date"
              item
              sort-by="created_at"
              sort-desc
              :loading="reportsLoading"
              :height="tableHeight"
              :footer-props="{ itemsPerPageOptions: itemsOptions }"
              tile
            >
              <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDateAndHour(item.created_at, true) }}
              </template>
              <template v-slot:[`item.author.name`]="{ item }">
                <v-avatar left size="30" class="mr-4">
                  <img :src="item.author.photo" />
                </v-avatar>
                <span>{{ item.author.name }}</span>
              </template>
              <template v-slot:[`item.main_domain`]="{ item }">
                <span
                  class="blue--text text--darken-2 text-body-2 font-weight-medium link"
                  @click.stop="
                    $router.push({
                      path: '/partner/customer',
                      query: {
                        domain: item.main_domain,
                        key: item.company_key,
                      },
                    })
                  "
                  >{{ item.main_domain }}
                </span>
              </template>
              <template v-slot:[`item.event_code`]="{ item }">
                <v-chip :color="eventInfo[item.event_code].color" small
                  >{{ eventInfo[item.event_code].text }}
                  <v-icon class="ml-2" small>{{
                    eventInfo[item.event_code].icon
                  }}</v-icon>
                </v-chip>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <ConfirmationDialog
      v-if="companySelection"
      :show="showConfirmBanClient"
      :target="companySelectedInfo[0].name"
      :action-text="
        companySelectedInfo[0].is_banned
          ? 'Desbanir o cliente'
          : 'Banir o cliente'
      "
      :loading="loading"
      width="700"
      @close="
        (showConfirmBanClient = false),
          (isUnbanningCompany = false),
          (companySelection = null)
      "
      @ok="banOrUnbanClient()"
      hideReversibleText
      :alert="{
        show: true,
        message:
          'ATENÇÃO: Antes de prosseguir COMUNIQUE a equipe sobre a decisão a seguir, pois isso influência no fluxo de entrada do cliente e consequentemente seus planos.',
        type: 'error',
      }"
      color="error"
      dense
      customBodyClass="px-0"
    >
      <v-textarea
        v-model="banReason"
        name="input-3-1"
        label="Motivo da alteração"
        auto-grow
        outlined
      ></v-textarea>
    </ConfirmationDialog>
  </v-container>
</template>
<script>
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";
import { mapGetters, mapMutations } from "vuex";
import { paging, tableHeight } from "@/helpers/variables/tablesDefault";
import { formatDateAndHour } from "@/helpers/services/utils";
export default {
  name: "BanClients",

  components: {
    ConfirmationDialog,
  },

  data() {
    return {
      tableHeight,
      companySelected: null,
      companySelection: null,
      companies: null,
      companiesUnbanned: null,
      isUnbanningCompany: false,
      showDialogBanClient: false,
      loading: false,
      showConfirmBanClient: false,
      headers: [
        { text: "Data do banimento", value: "ban_info.ban_date" },
        { text: "Nome", value: "name" },
        { text: "Ator", value: "ban_info.author_name" },
        { text: "Domínio", value: "main_domain" },
        { text: "", value: "action", sortable: false, align: "right" },
      ],
      banHeaders: [
        { text: "Data do registro", value: "created_at" },
        { text: "Evento", value: "event_code" },
        { text: "Domínio", value: "main_domain" },
        { text: "Ator", value: "author.name" },
      ],
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      panel: null,
      banLogs: [],
      reportsLoading: false,
      eventInfo: {
        unbanned: {
          color: "success",
          icon: "mdi-lock-open-variant",
          text: "Desbanido",
        },
        banned: { color: "error", icon: "mdi-block-helper", text: "Banido" },
      },
      banReason: "",
    };
  },

  methods: {
    ...mapMutations(["setSnackBar"]),

    formatDateAndHour,

    async getBanLogs() {
      this.reportsLoading = true;
      await this.$axios
        .get(process.env.VUE_APP_API_BASE_URL + "/company/ban/logs", {
          headers: {
            Authorization: this.token,
          },
        })
        .then((response) => {
          this.banLogs = response.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.reportsLoading = false;
        });
    },

    async getCompanies() {
      if (!this.companies) {
        this.loading = true;
        await this.$axios
          .get(process.env.VUE_APP_API_BASE_URL + "/company/list", {
            headers: {
              Authorization: this.token,
            },
          })
          .then((response) => {
            this.companies = response.data;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async banOrUnbanClient() {
      this.loading = true;
      this.companySelected = this.companies.filter(
        (company) => company.key === this.companySelection
      );
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/ban/${this.companySelection}`;
      let auth = {
        headers: {
          Authorization: this.token,
        },
      };
      const payload = {
        is_banned: !this.companySelected[0].is_banned,
        reason: this.banReason,
      };
      await this.$axios
        .put(url, payload, auth)
        .then(({ data }) => {
          this.updateCompanies(data);
          this.setSnackBar({
            show: true,
            message: payload.is_banned
              ? "Cliente banido com sucesso"
              : "Cliente desbanido com sucesso",
          });

          this.banReason = "";
        })
        .catch((e) => {
          console.error("Vuex: banOrUnbanClient(): ", e);
          this.setSnackBar({
            show: true,
            message: payload.is_banned
              ? "Houve um erro ao banir o cliente"
              : "Houve um erro ao desbanir o cliente",
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
          this.showConfirmBanClient = false;
          this.companySelection = null;
          this.isUnbanningCompany = false;
          this.resetBanClientDialog();
        });
    },
    updateCompanies(data) {
      this.companies = this.companies.map((company) => {
        if (data.key === company.key) {
          return data;
        }
        return company;
      });
    },
    pushToClients() {
      this.$router.push("/partner/customers");
    },
    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase().trim();
      const textTwo = item.main_domain.toLowerCase().trim();
      const searchText = queryText.toLowerCase().trim();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    resetBanClientDialog() {
      this.showDialogBanClient = false;
    },
  },

  computed: {
    ...mapGetters(["token"]),

    showPanel() {
      return this.panel === 0;
    },

    showReports: {
      get() {
        return this.panel;
      },
      set(value) {
        this.panel = value;

        if (value === undefined) {
          this.banLogs = [];
        } else {
          this.getBanLogs();
        }
      },
    },

    companySelect() {
      return this.companySelection;
    },
    companySelectedInfo() {
      if (this.companies) {
        return this.companies.filter(
          (company) => company.key === this.companySelection
        );
      }
      return {};
    },
    bannedCompanies() {
      if (this.companies) {
        return this.companies.filter((company) => company.is_banned);
      }
      return [];
    },
    unbannedCompanies() {
      if (this.companies) {
        return this.companies.filter((company) => !company.is_banned);
      }
      return [];
    },
  },
  beforeMount() {
    this.getCompanies();
  },
};
</script>
<style>
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>
